<template>
  <div class="product-wrap programme-irrigation">
    <Header is-active="programme" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-irrigation"></div>
    <div class="white-wrap">
      <div class="max-center">
        <div class="txt-box align-ct no-indent pd-5">
          <p>智慧灌区遵循人、水、灌区和谐发展的客观规律，在灌区信息化的基础上，</p>
          <p>融合人工智能和灌区用水全过程模拟仿真技术，依据以水定需、量水而行、因水制宜原则，</p>
          <p>实现灌区智慧预警、智慧调度/调控及智慧决策，推动灌区发展与水资源和水环境承载力相协调，</p>
          <p>发展完整的灌区水生态系统，建立灌区永久水资源保障制度，构建先进的灌区水科技文化。</p>
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big align-ct"><h4>行业痛点 </h4></div>
        <div class="pain-box mt-2">
          <div class="td-item">
            <img src="../../assets/img/programme/irrigation/td_a.jpg" alt="设备老化不配套">
            <p class="color-59">设备老化不配套</p>
          </div>
          <div class="td-item">
            <img src="../../assets/img/programme/irrigation/td_b.jpg" alt="监测数据不准确">
            <p class="color-59">监测数据不准确</p>
          </div>
          <div class="td-item">
            <img src="../../assets/img/programme/irrigation/td_c.jpg" alt="水资源利用不充分">
            <p class="color-59">水资源利用不充分</p>
          </div>
          <div class="td-item">
            <img src="../../assets/img/programme/irrigation/td_d.jpg" alt="水费记收不合理">
            <p class="color-59">水费记收不合理</p>
          </div>
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <div class="fz-big align-ct"><h4>云昇方案</h4></div>
        <div class="txt-box">
          <p>
            智慧灌区信息化系统综合解决方案是为灌区信息化管理专门设计的智慧管理系统，系统通过传感器（如水位计、流量计、闸位计、水质传感器、综合气象等装置）进行水利信息采集，利用计算机局域网、GPRS、2G/3G/4G、ZigBee、LoRa、VHF、有线通信网等，对监测点、监控中心及灌区信息中心之间进行信息的传输，实现灌区信息采集、传输、处理，形成综合数据库。实现科学、及时和准确的调配灌溉用水，达到节约用水，高产、高效的目的，保证了系统的安全，也为灌区水资源的管理提供了基础、有效的数据，提高了企业的管理水平和生产效率。</p>
          <p>
            灌区信息化系统通过传感器（如水位计、流量计、闸位计、水质传感器、综合气象等装置）进行水利信息采集，利用计算机局域网、GPRS、2G/3G/4G、ZigBee、LoRa、VHF、有线通信网等进行监测点、监控中心和灌区信息中心之间的信息传输，实现灌区信息采集、传输、处理，形成综合数据库。同时，实现灌区内水雨情信息、水质信息、墒情、闸位、气象信息的实时监测，以及各水利机电设施的自动控制（如灌区各配水点的闸位、水源站的电机泵阀及闸门等装置进行远程控制和管理）。实现灌区业务管理，包括水量调度、水费征收和日常办公管理。</p>
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big align-ct"><h4>方案架构</h4></div>
        <div class="auto-img">
          <img src="../../assets/img/programme/irrigation/jg.png" alt="方案架构">
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center">
        <div class="fz-big align-ct"><h4>方案价值</h4></div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-kaifang"></i></div>
            <p>开放式结构</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-GIS"></i></div>
            <p>GIS模块</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-kuozhan"></i></div>
            <p>可扩展性</p>
          </div>
        </div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-wending1"></i></div>
            <p>稳定可靠</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-hlcc"></i></div>
            <p>海量存储</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-gongyeji"></i></div>
            <p>工业级设计</p>
          </div>
        </div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-linghuo"></i></div>
            <p>灵活的硬件配置</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-kangganrao"></i></div>
            <p>抗干扰性</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-jiance"></i></div>
            <p>故障自动检测</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <BackTop></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '智慧灌区-方案中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控智慧灌区-方案中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.programme-irrigation {
  .banner-irrigation {
    background: url("../../assets/img/programme/irrigation/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }
}

</style>
